import './Dashboard.css';
import React, { useState } from 'react';
import { Tab } from 'bootstrap';
import { Tabs } from 'react-bootstrap';
import AbbreviateAddress from '../abbreviate/AbbreviateAddress';
import AbbreviateState from '../abbreviate/AbbreviateState';
import Alerts from '../alerts/Alerts.js';

export default function Dashboard() {
    const [alertSuccesses, setAlertSuccesses] = useState([]);
    const [alertInfos, setAlertInfos] = useState([]);
    const [alertWarnings, setAlertWarnings] = useState([]);
    const [alertErrors, setAlertErrors] = useState([]);

    const addInfo = info => {
        setAlertInfos(alertInfos => [...alertInfos, info]);
    }

    const addSuccess = success => {
        setAlertSuccesses(alertSuccesses => [...alertSuccesses, success]);
    };

    const addWarning = warning => {
        setAlertWarnings(alertWarnings => [...alertWarnings, warning]);
    };

    const addError = error => {
        setAlertErrors(alertErrors => [...alertErrors, error]);
    };

    const clearAlerts = () => {
        setAlertInfos([]);
        setAlertSuccesses([]);
        setAlertWarnings([]);
        setAlertErrors([]);
    }

    return (
        <div className='dashboard'>
            <Alerts 
                successes={alertSuccesses}
                infos={alertInfos}
                warnings={alertWarnings}
                errors={alertErrors}
            />
            <Tabs defaultActiveKey="address" className="mb-3">
                <Tab eventKey="address" title="Address">
                    <AbbreviateAddress
                        addWarning={addWarning}
                        addError={addError}
                        clearAlerts={clearAlerts}
                    />
                </Tab>
                <Tab eventKey="state" title="State">
                    <AbbreviateState
                        addWarning={addWarning}
                        addError={addError}
                        clearAlerts={clearAlerts}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}