import './AbbreviateState.css';
import React, { useState } from 'react';
import * as APIUtils from '../../api/APIUtils';
import { Button, Col, Container, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import Submit from '../buttons/Submit';
import Copy from '../buttons/Copy';

export default function AbbreviateState({addWarning, addError, clearAlerts}) {
    const [processing, setProcessing] = useState(false);
    const [states, setStates] = useState([]);
    const [output, setOutput] = useState([]);

    const handleStatesChange = event => {
        let states = event.target.value.split('\n');
        setStates(states);
    }

    const handleOutputChange = event => {
        let output = event.target.value.split('\n');
        setOutput(output);
    }

    const handleReset = () => {
        setStates([]);
        setOutput([]);
        clearAlerts();
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        clearAlerts();
        setProcessing(true);
        const path = '/api/abbreviate/states';
        const data = JSON.stringify(states);
        APIUtils.postJSON(path, data).then(({status, json}) => {
            setProcessing(false);
            switch(status) {
                case 200: 
                    setOutput(json);
                    break;
                case 400:
                    addWarning(json.message);
                    break;
                case 500:
                    addError(json.message);
                    break;
                default:
                    addError(json);
            }
        });
    }

    return (
        <div>
            <Container fluid='true'>
                <Row xs={1} md={2}>
                    <Col>
                        <Form onSubmit={handleSubmit}>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="States"
                            >
                            <Form.Control 
                                as="textarea" 
                                placeholder='States'
                                value={states.join('\n')} 
                                onChange={handleStatesChange}
                                style={{ height: '350px' }}
                                className="mb-3"
                                required
                                />
                            </FloatingLabel>
                            <Stack direction="horizontal" gap={2} className="mb-3">
                                <Submit buttonText={'Abbreviate'} processing={processing}/>
                                <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            </Stack>
                        </Form>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Abbreviated"
                            className="mb-3"
                        >
                            <Form.Control 
                                as="textarea" 
                                placeholder='Abbreviated'
                                value={output.join('\n')} 
                                onChange={handleOutputChange}
                                style={{ height: '350px' }}
                            />
                        </FloatingLabel>
                        <Copy content={output.join('\n')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}